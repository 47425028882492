@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.banner {
  &__message {
    :last-of-type {
      text-decoration: underline;
    }
  }

  &__modal-portal {
    width: 448px;
    min-block-size: auto;
    text-align: center;
    justify-content: space-between;
    padding: 24px;

    header,
    article,
    footer {
      padding-inline: 0;
    }

    [class*='modal-close-button'] {
      padding: 0;
    }
    header {
      [class*='modal-title'] {
        visibility: visible;
        text-align: start;
      }
    }
    footer {
      justify-content: center;
      margin-block-start: 32px;
      padding: 0;
    }
  }

  &__modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: $color-white;
    display: none;
    margin-block-start: 24px;
  }
  &__modal-text {
    display: block;
    color: $color-gray-100;
    margin-block-start: 32px;
  }
  &__modal-trigger {
    background: $color-success;
    text-transform: uppercase;
    width: 100%;
    padding: 12px 16px;
    border-radius: 3px;
    font-weight: 600;
    transition: $transition-hover;
    height: 48px;

    &:hover {
      background: $color-success-medium;
      border-color: $color-success-medium;
    }
    &:disabled {
      background: $color-gray-400;
      border-color: $color-gray-400;
      color: $color-gray-200;
      cursor: not-allowed;
    }
  }
}

@include media-breakpoint-up('md') {
  .banner {
    &__modal-portal {
      max-width: 448px;
      width: 100%;
      border-top: 4px solid $color-success;
      max-block-size: fit-content;

      [class*='modal-content'] {
        overflow: unset;
      }
      header {
        [class*='modal-title'] {
          visibility: hidden;
        }
      }
      footer {
        margin-block-start: 48px;
      }
    }
    &__modal-title {
      display: block;
    }
    &__modal-text {
      padding: 0;
      margin-block-start: 8px;
    }
  }
}
