@import '@ping/assets/scss/theme/variables';

.alert-icon {
  inline-size: 1.5rem;
  block-size: 1.5rem;
  aspect-ratio: 1;

  display: grid;
  place-content: center;

  &[data-variant='warning'] {
    color: $color-warning;
    fill: $color-warning;
  }
  &[data-variant='error'] {
    color: $color-error;
    fill: $color-error;
  }
}
