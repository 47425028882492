@import '@ping/assets/scss/theme/variables';

.modal-title {
  flex-grow: 1;
  color: $color-white;
  margin: 0; // TODO: remove global style

  /* Headlines/H4 */
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
