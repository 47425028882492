@import './theme';
@import './_variables';

.dark,
.dark header {
  background: $color-black;
}
.dark header nav a {
  color: $color-gray-200;
  transition: $transition-hover;
}
.dark header nav a:hover {
  color: $color-white;
}
.dark .nav-link-active {
  color: $color-white;
}
.dark header nav a.btn:hover {
  color: $color-white;
}
.dark header nav .nav-item.active a {
  color: $color-white;
}
.dark .market-pairs {
  border: 1px solid $color-gray-300;
  background: $color-black;
}
.dark .order-book,
.dark .market-history,
.dark .market-news {
  border: 1px solid $color-gray-300;
}

// .dark .markets-pair-list .nav-link {
//   color: $color-gray-200;
// }
// .dark .form-control::-webkit-input-placeholder {
//   /* Chrome/Opera/Safari */
//   color: $nevada;
// }
// .dark .form-control::-moz-placeholder {
//   /* Firefox 19+ */
//   color: $nevada;
// }
// .dark .form-control:-ms-input-placeholder {
//   /* IE 10+ */
//   color: $nevada;
// }
// .dark .form-control:-moz-placeholder {
//   /* Firefox 18- */
//   color: $nevada;
// }
.dark .market-pairs .nav-link.active,
.dark #STAR i {
  color: $color-white;
}
.dark .green {
  color: $color-success;
}
.dark .red {
  color: $color-error;
}
.dark .market-pairs .nav {
  background: $color-gray-600;
}
.dark .market-pairs input {
  border-color: $color-gray-300;
  background: $color-gray-300;
}
.dark .market-pairs input {
  color: $color-white;
}
// .dark .ob-heading span {
//   color: $color-gray-200;
// }
.dark .market-history .nav {
  background: $color-gray-600;
}

.dark .red-bg-80:after,
.dark .red-bg-60:after,
.dark .red-bg-40:after,
.dark .red-bg-20:after,
.dark .red-bg-10:after,
.dark .red-bg-8:after,
.dark .red-bg-5:after,
.dark .red-bg:after {
  background: #ff231f63;
}
.dark .market-news {
  background: $color-black;
}
.dark .market-news li {
  border-bottom: 1px solid $color-gray-300;
}
.dark .market-news li:hover {
  background: $color-gray-300;
}
// .dark .market-news li strong,
// .dark .market-news li a {
//   color: $loblolly;
// }

.dark {
  .nav-tabs {
    .nav-link {
      border: transparent;
      &:hover {
        border: transparent;
      }
      &.active {
        background: transparent;
        border: transparent;
      }
    }
  }
}
.dark .market-trade {
  border: 1px solid $color-gray-300;
  background: $color-black;
}

.dark .market-trade-overlay {
  .market-trade-overlay__title {
    color: $color-white;
  }
  background: linear-gradient(180deg, rgba(19, 23, 34, 0.95) 14.1%, rgba(19, 23, 34, 0.7) 100%);
}

.dark .market-trade .nav .nav-item .nav-link.active {
  background: transparent;
  color: $color-white;
}
// .dark .market-trade .nav .nav-item .nav-link {
//   color: $color-gray-200;
// }
// .dark .market-trade p {
//   color: $loblolly;
// }
.dark .market-trade-list li a {
  background: $color-gray-300;
  color: $color-white;
}
.dark .market-trade-buy .market-trade-list li a:hover {
  background: $color-success;
  color: $color-white;
}
.dark .market-trade-sell .market-trade-list li a:hover {
  background: $color-error;
  color: $color-white;
}
.dark #darkDepthChart {
  background: $color-black;
}
.dark .markets {
  background: $color-black;
}

.dark .markets-pair-list .nav {
  background: $color-gray-600;
}
.dark .markets-pair-list .nav-link.active,
.dark #STAR i {
  color: $color-white;
}
.dark .markets-pair-list .load-more {
  border: 1px solid $color-gray-300;
  color: $color-white;
}
.dark .markets-pair-list .load-more:hover {
  color: $color-white;
  border: 1px solid $color-primary;
}
.dark .markets-content h2,
.dark .markets-content p {
  color: $color-white;
}
.dark .markets-content span.green,
.dark .markets-content span.red {
  color: $color-white;
}
// .dark .markets-container {
//   background: $ebony-clay;
// }

.dark header nav .nav-item.active a.btn:hover {
  color: $color-white;
}

.dark .custom-control-input:checked ~ .custom-control-label::before {
  color: $color-white;
  border-color: $color-primary;
  background-color: $color-primary;
}

.dark .dropdown-menu p {
  color: $color-white;
}

.dark .dropdown-header {
  border-bottom: 1px solid $color-gray-300;
}
.dark .dropdown-footer {
  border-top: 1px solid $color-gray-300;
}

.dark .header-img-icon .profile-nav .nav-item .nav-link:hover {
  background-color: $color-gray-300;
  color: $color-white;
}

.dark .form-control,
.dark .settings-profile select {
  box-sizing: border-box;
  background-color: $color-gray-500 !important;
  // border: 1px solid #3e445c !important;
  height: 3rem;
  border: unset;
  border-radius: 0.125rem;
  color: $color-white;

  &:focus {
    color: $color-white;
    outline: none;
    box-shadow: none;
  }
}

.dark .has-input-error {
  border: 1px solid $color-error !important;
}

.dark .form-access form {
  padding: 30px;
  border-radius: 5px;
  min-width: 350px;
  box-shadow: 0 0 10px 0 $color-black;
  border: 1px solid $color-gray-300;
}
.dark .form-access h2,
.dark .form-access {
  color: $color-white;
}

.dark .form-access .custom-control-label::before {
  border: $color-gray-300 solid 1px;
  background-color: $color-gray-300;
}

.dark .wallet .nav-pills a,
// .dark .wallet-history {
//   border-top: 1px solid $color-gray-300;
// }
// .dark .wallet .nav {
//   background: $ebony-clay;
// }
.dark .wallet h2,
.dark .wallet h3,
.dark .wallet h4,
.dark .wallet p {
  color: $color-white !important;
}
.dark .wallet button.green,
.dark .wallet button.red,
.dark .wallet .nav-pills a.active h2,
.dark .wallet .nav-pills a.active h3,
.dark .wallet .nav-pills a.active p {
  color: $color-white;
}

.dark .wallet .tab-content li:first-child {
  border-bottom: 1px solid $color-gray-300;
}
// .dark .wallet-history tr {
//   background: $ebony-clay;
// }
.dark .wallet-address input,
.dark .wallet-address input:focus {
  border: 1px solid $color-gray-300;
  background: $color-gray-300;
  box-shadow: none;
  color: $color-white;
}
.dark .depth-chart-inner {
  border: 1px solid $color-gray-300;
}

.dark .markets-capital-item {
  box-shadow: 0 0 10px 0 $color-black;
  border: 1px solid $color-gray-300;
}

.dark .markets-capital-item h2,
.dark .markets-capital-item h4 {
  color: $color-white;
}

// .dark .settings-nav {
//   background: $ebony-clay;
// }

// .dark .settings-nav .nav-link {
//   color: $color-white;
// }

// .dark .settings-notification li {
//   border-bottom: 1px solid $color-gray-300;
// }
// .dark .settings-notification li:last-child {
//   border-bottom: 0;
// }
// .dark .settings-notification p {
//   color: $color-white;
// }
// .dark .settings-notification span {
//   color: $color-gray-200;
// }

.dark .settings label {
  color: $color-white;
}

.dark .settings-profile input[type='submit'] {
  border: 1px solid $color-primary;
}

.dark .sidebar {
  &-link {
    color: $color-white;
    @include svgColor($color-white);
  }
}

.dark .market-carousel-item {
  border: 1px solid $color-gray-300;
}
.dark .market-carousel-item {
  color: $color-white;
}
.dark .market-carousel-item .btn.buy:hover,
.dark .market-carousel-item .btn.sell:hover {
  color: $color-white;
  border-color: $color-white;
}
.dark .navbar-toggler-icon {
  filter: contrast(0.1);
}
