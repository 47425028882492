@import '@ping/assets/scss/theme/variables';

.text {
  color: $color-white;
  &.heading {
    &_4 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 0.2px;
    }
    &_3 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      letter-spacing: 0.6px;
    }
    &_2 {
      font-size: 36px;
      line-height: 56px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    &_1 {
      font-size: 48px;
      line-height: 72px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
  }

  &.body {
    &_medium {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
    &_regular {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &_semi-bold {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.caption {
    &_regular {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    &_semi-bold {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &.text {
    &_electric-blue {
      color: #5a6282; // TODO
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }
    &_cadet-blue {
      color: $color-gray-100;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.isError {
    display: block;
    margin-top: 8px;
    color: $color-error;
  }
  &.cadetBlueColor {
    color: $color-gray-100;
  }
  &.grayColor {
    color: $color-gray-200;
  }
}
