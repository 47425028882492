@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.avatar-wrapper {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  color: $color-white;
  border: 1px solid $color-gray-400;
  border-radius: 1.5rem;
  gap: 1.5rem;
  background: $color-gray-700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @include media-breakpoint-down('sm') {
    gap: 0.7rem;
  }

  &:hover {
    background: $color-gray-500;
    border-color: $color-primary-light;
  }

  &:focus {
    border-color: $color-primary-light;
  }

  .avatar-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 500;

    .avatar-image {
      border-radius: 100%;
    }
  }

  .avatar-tier {
    text-transform: uppercase;
    min-inline-size: 55px;
    padding: 5px 12px;
    background-color: $color-primary;
    border-radius: 0.8rem;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: $color-white;
    letter-spacing: 0.2px;
  }
}
