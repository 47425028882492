@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.provide-requested-documents-modal-trigger {
  /* 02 Body HC/semibold */
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  padding: 0.875rem 2rem;
  block-size: 48px;
  inline-size: 100%;
  background: $color-primary;
  border-color: $color-primary;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.24s ease-in-out;

  @include media-breakpoint-up('md') {
    inline-size: 16.25rem;
  }

  &:hover {
    background-color: $color-primary-medium;
    border-color: $color-primary-medium;
  }
}
