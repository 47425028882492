@use 'sass:color';

@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.modal-portal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;

  &[data-invisible='true'] {
    transition: all 0.24s; // underlay transition time
    visibility: hidden;
    pointer-events: none;
  }

  &__underlay {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: color.adjust($color-black, $alpha: -0.15);
    opacity: 0;
    transition: all 0.24s cubic-bezier(0.25, 0, 0.3, 1);

    &[data-transition='enter'] {
      opacity: 1;
    }
  }

  &__container {
    color: $color-gray-100;
    background-color: $color-gray-700;
    border: 1px solid $color-gray-400;
    margin-block-start: auto;
    max-block-size: 90%;
    min-block-size: 35%;
    inline-size: 100%;
    overflow: hidden;
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    transform: translate3d(0, 100vh, 0);

    &[data-divide='true'] {
      & > * + * {
        border-block-start: 0.5px solid $color-gray-300;
      }
    }

    &[data-expand='true'] {
      max-block-size: 100%;
      block-size: 100%;
      max-inline-size: 100%;
      inline-size: 100%;
      border-radius: 0;
    }

    @keyframes slideInUp {
      from {
        visibility: hidden;
      }
      to {
        transform: translate3d(0, 0, 0);
        visibility: visible;
        opacity: 1; // non-mobile devices; check line 85
      }
    }
    &[data-transition='enter'] {
      animation: slideInUp 0.48s 1 forwards cubic-bezier(0.25, 0, 0.3, 1);
    }

    @keyframes slideOutDown {
      from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      to {
        visibility: hidden;
        transform: translate3d(0, 100vh, 0);
      }
    }
    &[data-transition='leave'] {
      animation: slideOutDown 0.36s 1 forwards cubic-bezier(0.25, 0, 0.3, 1);
    }

    @include media-breakpoint-up('sm') {
      max-block-size: 100%;
      max-inline-size: 66%;
      border-radius: 0;
      border: none;
      opacity: 0;

      &[data-entrance='center'] {
        margin: auto;
      }

      &[data-entrance='start'] {
        transform: translate3d(-100vh, 0, 0);
        margin-inline-end: auto;
        border-inline-end: 1px solid $color-gray-400;
      }

      @keyframes slideInStart {
        from {
          visibility: hidden;
        }
        to {
          transform: translate3d(0, 0, 0);
          visibility: visible;
          opacity: 1; // non-mobile devices; check line 85
        }
      }
      &[data-transition='enter'][data-entrance='start'] {
        animation: slideInStart 0.48s 1 forwards cubic-bezier(0.25, 0, 0.3, 1);
      }

      @keyframes slideOutStart {
        from {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        to {
          visibility: hidden;
          transform: translate3d(-100vh, 0, 0);
        }
      }
      &[data-transition='leave'][data-entrance='start'] {
        animation: slideOutStart 0.36s 1 forwards cubic-bezier(0.25, 0, 0.3, 1);
      }
    }

    @include media-breakpoint-up('lg') {
      margin: auto;
      max-block-size: 60%;
      max-inline-size: 55%;
      inline-size: auto;
      border-radius: 3px;
      border: none;
      opacity: 0;

      @media (max-height: 817px) {
        max-block-size: 100%;
      }

      @media (min-height: 818px) and (max-height: 1000px) {
        max-block-size: 73%;
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        to {
          opacity: 0;
          transform: translate3d(0, 0, 0);
        }
      }
      &[data-transition='leave'] {
        animation: fadeOut 0.24s 1 forwards cubic-bezier(0.25, 0, 0.3, 1);
      }
    }
  }
}
