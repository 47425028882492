@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes rotation {
  from {
    transform: var(--rotation-transform-preserve, translate3d(0, 0, 0));
  }
  to {
    transform: var(--rotation-transform-preserve, translate3d(0, 0, 0)) rotate(360deg);
  }
}
