@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

body {
  /* Start the scroll bar from under the navbar */
  /* Related changes: "src/components/Page/style.module.scss" */
  overflow: hidden;
}

/* fix to not shift page on big number in react aria inputs till the new version with fix get released */
/* PR: https://github.com/adobe/react-spectrum/issues/1216 */
[data-live-announcer='true'] {
  height: 1px;
  width: 1px;
}

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
  height: 6px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: $color-gray-500; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  border-radius: 2px; /* roundness of the scroll thumb */
  border: 4px solid $color-gray-400; /* creates padding around scroll thumb */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $color-gray-400 $color-gray-500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-gray-500 inset !important;
  -webkit-text-fill-color: $color-white;
  caret-color: $color-white;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.mtb15 {
  margin: 15px 0;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.ptb70 {
  padding: 70px 0;
}
.pb70 {
  padding-bottom: 70px;
}
.pt70 {
  padding-top: 70px;
}
.pb40 {
  padding-bottom: 40px;
}

.dropdown-toggle::after {
  vertical-align: 1px;
}
.input-group-text,
.form-control {
  border-radius: 5px;
}
.nav-lin {
  color: $color-gray-200;
}
.center-column {
  display: block;
  text-align: center;
}
.green {
  color: $color-success;
}
.red {
  color: $color-error;
}
.dark-bb {
  border-bottom: 1px solid $color-gray-300;
}
#darkDepthChart,
#lightDepthChart {
  width: 100%;
  height: 349px;
}
.sm-gutters {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.sm-gutters > .col,
.sm-gutters > [class*='col-'] {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-gray-100;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-gray-100;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-gray-100;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: $color-gray-100;
}

// Textarea
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: $color-gray-200;
}
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-gray-200;
}
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-gray-200;
}
textarea::-webkit-input-placeholder {
  /* Webkit based */
  color: $color-gray-200;
}
textarea::placeholder {
  /* Modern browsers */
  color: $color-gray-200;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.nav-tabs {
  border-block-end: 0;
}
.navbar-nav {
  flex-direction: unset;
}
.nav-tabs .nav-link {
  color: $color-gray-200;
  font-size: 16px;
  transition: $transition-hover;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  color: white;
  background-color: transparent;
}
.slick-slide > div {
  margin: 0 8px;
}
.slick-list {
  margin: 0 -8px;
}
.news-details {
  padding: 100px 0;
}

.news-details h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.news-details p {
  font-size: 16px;
}

.has-input-error {
  border: 1px solid $color-error !important;
}

.input-error {
  color: $color-error;
  font-size: 14px;
  line-height: initial;
  display: block;
  margin-top: 8px;
}

/* --------------------------- */
/* --------- TOOLTIP --------- */
/* --------------------------- */

.tooltip > .tooltip-inner {
  background-color: $color-gray-500;
  color: white;
  border: 1px solid $color-gray-500;
  max-width: 100%;
}

.tooltip {
  &[x-placement='top'] {
    .arrow:before {
      border-top-color: $color-gray-500;
    }
    inset: auto auto 6px 0px !important;
  }
  &[x-placement='right'] {
    .arrow:before {
      border-right-color: $color-gray-500;
    }
    inset: 0px auto auto 6px !important;
  }
  &[x-placement='left'] {
    .arrow:before {
      border-left-color: $color-gray-500;
    }
    inset: 0px 6px auto auto !important;
  }
  &[x-placement='bottom'] {
    .arrow:before {
      border-bottom-color: $color-gray-500;
    }
    inset: 6px auto auto 0px !important;
  }
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.grow {
  flex-grow: 1;
}

.center {
  display: grid;
  place-content: center;
  place-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.trading-records__no-data-icon {
  position: relative;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

/* --------------------------- */
/* ---------- STATUS --------- */
/* --------------------------- */

.status {
  &--green {
    color: $color-success;
  }
  &--red {
    color: $color-error;
  }
  &--blue {
    color: $color-primary;
  }
  &--yellow {
    color: $color-warning;
  }
  &--gray {
    color: $color-gray-200;
  }
}

/* ------------------------------- */
/* ---------- LAYOUT TITLE --------- */
/* ------------------------------- */
.layout-title {
  /* Headlines/H2 */
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;

  color: $color-white;
  display: block;
  margin: 0;
}

@keyframes modal-slide {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@include media-breakpoint-up('md') {
  .popup-modal-drawer {
    left: unset !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    transform: unset !important;
    max-height: 100% !important;

    &-content {
      height: 100% !important;
      max-height: 100% !important;
      animation: modal-slide 0.2s linear !important;
    }
  }
}
