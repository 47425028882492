@import '@ping/assets/scss/theme/variables';

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-white;
  height: 100dvh;
  max-width: 640px;
  margin: auto;
  text-align: center;
  padding-inline: 32px;

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  &__description {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $color-gray-200;
  }

  &__btn {
    margin-top: 40px;
  }
}
