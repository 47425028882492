@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/breakpoint';

@keyframes pulse-warning {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-warning, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-warning, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-warning, 0);
  }
}

@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-error, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-error, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-error, 0);
  }
}

.nav-item-icon {
  margin-right: 8px;
  width: 24px;
}

.nav-user {
  align-items: center;

  #nav-dropdown-currency {
    & ~ :global.dropdown-menu {
      width: 70px;
      min-width: unset;
      left: -15px;
    }
  }
}
.nav-language {
  border-inline-end: 1px solid $color-gray-400 !important;

  margin-inline-end: 0 !important;
  padding-inline-end: 14px !important;
}

.nav-currency {
  @media (max-width: map-get($breakpoints, 'md')) {
    display: none;
  }
}

.nav-item {
  padding: 0;

  > [data-disabled='true'] {
    color: $color-gray-300 !important;
    cursor: not-allowed !important;

    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    background: unset;
  }
  &__content {
    display: inline-flex;
    align-items: center;
    color: $color-gray-100;
    width: inherit;
  }
  &__content-title {
    color: $color-white;
    width: inherit;
  }
  &__label {
    padding: 5px 12px;
    background-color: $color-primary;
    border-radius: 0.8rem;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: $color-white;
    letter-spacing: 0.2px;
  }

  &__divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid $color-gray-400;
  }
}

.nav-currency-dropdown {
  display: flex;
  justify-content: center;
  color: $color-white !important;
  font-size: 16px;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: $color-gray-300;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.header {
  position: sticky;
  height: $header-height;
  top: 0;
  z-index: 901; // go over footer on mobile trade page
  width: 100%;
  background-color: $color-white;

  :global .nav-link {
    padding: 0 12px !important;

    @media (max-width: map-get($breakpoints, 'md')) {
      padding: 0 !important;
    }
  }
  :global .dropdown-menu {
    background: $color-gray-600;
    box-shadow: 0 0 10px 0 $color-black;
    border: 1px solid $color-gray-400;
    margin-top: 8px !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: slideIn;
  }
  :global .dropdown-menu::before {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    background: $color-gray-500;
    border-top: 1px solid $color-gray-300;
    border-left: 1px solid $color-gray-300;
    z-index: -1;
  }
  :global .navbar {
    background-color: $color-black;
    border-bottom: 1px solid $color-gray-400;
    justify-content: space-between;
    height: 100%;

    :global .navbar-brand {
      @media screen and (max-width: map-get($breakpoints, 'sm')) {
        margin-right: 3px;
      }
    }
  }

  &__navbar {
    flex-direction: row !important;
  }

  &__links-nav {
    flex-grow: 1;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: map-get($breakpoints, 'lg')) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
    }

    @media (max-width: map-get($breakpoints, 'md')) {
      display: none;
    }
  }

  &__link {
    align-self: center;
    margin: 0.5rem 14px;
    text-decoration: none !important;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    color: $color-gray-100 !important;
  }
  &__link-btn {
    font-size: 16px;
    margin: 0.5rem 14px;
    background: transparent;
    border: none;
    color: $color-gray-200;
    transition: $transition-hover;
    padding: 0;

    &:disabled {
      color: $color-gray-300 !important;
      cursor: not-allowed !important;
      background-color: transparent;
    }

    &:hover {
      color: $color-white;
    }
  }

  &__link-active {
    color: $color-white !important;
  }

  &__link_disabled {
    color: $color-gray-300;
    cursor: not-allowed;
  }
}

.header__dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  padding: 12px 8px;
  transition: $transition-hover;

  @include media-breakpoint-down('sm') {
    padding: 8px;
    svg {
      path {
        stroke: $color-gray-100;
      }
    }
  }

  &:hover,
  &:focus,
  &.is-active {
    background-color: $color-gray-400;
    color: $color-white;
    text-decoration: none;
  }
}

.header__get-started-button {
  transition: $transition-hover;
  width: 163px !important;
  padding: 10px !important;
}

.logo {
  cursor: pointer;
  width: 86px;
  aspect-ratio: 86/36;
  @include media-breakpoint-down('md') {
    width: 77px;
  }
}

.logo-mobile {
  width: 32px;
}

.app-stage-badge {
  cursor: pointer;
  margin-left: 8px;
  width: 43px;
  height: 20px;
}

#nav-dropdown-profile:after {
  content: none;
}

#nav-dropdown-profile {
  ~ :global.dropdown-menu {
    position: absolute;
    width: 216px;
    right: 12px;
    padding: 12px 16px;
    margin-top: 8px !important;
    left: unset;
    border-radius: 8px;
    @include media-breakpoint-down('md') {
      right: 0;
    }
  }
  ~ :global.dropdown-menu::before {
    display: none;
  }
  ~ :global.dropdown-menu .dropdown-divider {
    border-top: 1px solid $color-gray-400;
  }
}

@include media-breakpoint-up('md') {
  .header__get-started-button {
    width: 208px !important;
    padding: 10px 32px !important;
  }
}

#trade-dropdown {
  color: $color-gray-100;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding: 0 !important;
  gap: 4px;
  display: flex;
  align-items: center;

  &[aria-expanded='true'] {
    color: $color-white;
    &:after {
      transform: rotate(180deg);
    }
  }

  ~ :global.dropdown-menu {
    position: absolute;
    width: 320px;
    right: 12px;
    padding: 12px;
    margin-top: 8px !important;
    border-radius: 8px;
    @include media-breakpoint-down('md') {
      right: 0;
    }
  }

  ~ :global.dropdown-menu::before {
    display: none;
  }
}

.trade__dropdown-item {
  display: flex;
  padding: 0;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  color: $color-gray-100 !important;

  &:hover {
    background-color: $color-gray-400;
    color: $color-white;
    text-decoration: none;
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    display: flex;
    padding: 12px;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    color: $color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
  }

  &__subtitle {
    color: $color-gray-100;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.trade-drop-down-modal {
  &-trigger {
    width: inherit;
    display: flex;
    justify-content: space-between;
    svg {
      path {
        stroke: $color-gray-200;
      }
    }
    &__text {
      font-weight: 400;
    }
  }

  &__divider {
    display: flex;
    flex-direction: column;
    padding: 8px 20px 21px 20px;
    justify-content: center;
    align-items: center;
    hr {
      width: 64px;
      height: 5px;
      flex-shrink: 0;
      border-radius: 5px;
      background: $color-gray-300;
      margin: 0;
    }
  }

  &__header {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: $color-white;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.36px;
  }
}

.pending-approval--btn {
  background: $color-gray-700;
  border: 1px solid $color-gray-400;
  width: 40px;
  height: 40px;
  border-radius: 10rem;
  margin-right: 4px;
  transition: all 200ms ease;
  &:hover {
    border: 1px solid $color-gray-200;
  }
  &:focus {
    border: 1px solid $color-primary-light;
  }
  &-text {
    display: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;

    @include media-breakpoint-down('sm') {
      display: inline-flex;
    }
  }

  @include media-breakpoint-down('sm') {
    width: 100%;
    margin-bottom: 28px;
  }

  &.is-rejected,
  &.is-pending-for-review,
  &.is-more-data-needed {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 10rem;
    }
  }
  &.is-rejected {
    animation: pulse-error 2s infinite;
    border: 1px solid $color-error;
  }
  &.is-rejected::after {
    background: $color-error;
    border: 2px solid $color-black;
  }
  &.is-more-data-needed,
  &.is-pending-for-review {
    animation: pulse-warning 2s infinite;
    border: 1px solid $color-warning;
  }
  &.is-more-data-needed::after,
  &.is-pending-for-review::after {
    background: $color-warning;
    border: 2px solid $color-black;
  }
}
