@import '@ping/assets/scss/theme/variables';

.alert-title {
  margin: 0; // reset browser style
  grid-column-start: 2;

  /* 01 Body/semibold */
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */

  &[data-variant='warning'] {
    color: $color-warning;
  }
  &[data-variant='error'] {
    color: $color-error;
  }
}
