@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.buttonA11Y {
  appearance: none;
  background: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  outline: none;
  user-select: none;
  padding: 0;

  /* 02 Body HC/semibold */
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.28px;
  border-radius: 4px;

  color: $color-white;

  &[data-primary='true'],
  &[data-secondary='true'] {
    text-transform: uppercase;
    width: 100%;
    padding: 14px 24px;
    outline: 0 !important;
  }

  &[data-primary='true'] {
    background-color: $color-primary;
    transition: $transition-hover;

    &:hover {
      background-color: $color-primary-medium;
    }

    &:focus {
      border: 2px solid $color-primary-light;
      background-color: $color-primary;
      outline: none !important;
    }

    &:disabled {
      background-color: $color-gray-400;
      color: $color-gray-200;
    }
  }

  &[data-secondary='true'] {
    border: 1px solid $color-primary;
    transition: $transition-hover;

    &:hover {
      border: 1px solid $color-primary-medium;
    }

    &:focus {
      border: 1px solid $color-primary-light;
    }

    &:disabled {
      background-color: $color-gray-600;
      border: 1px solid $color-gray-400;
      color: $color-gray-200;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &[data-disabled='true'][data-primitive='false'],
  &[data-loading='true'][data-primitive='false'] {
    background: $color-gray-400;
    border-color: $color-gray-400;
    color: $color-gray-200;
  }

  &[data-disabled='true'][data-primitive='true'],
  &[data-loading='true'][data-primitive='true'] {
    opacity: 0.3;
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
  }

  &[data-loading='true'] {
    cursor: progress;
  }

  &__loading {
    color: inherit !important;
    inline-size: 1.25rem;
    block-size: 1.25rem;
    flex-shrink: 0;
  }
}
