@import '@ping/assets/scss/theme/variables';

.pending-approval-modal {
  width: 720px;

  &__header {
    padding: 32px;
  }

  &__content {
    padding: 0 32px 16px;
    border-block-start: none !important;
    min-block-size: 29% !important;
    .more-documents--alert {
      margin-bottom: 32px;
    }
    .rejected {
      &--title {
        color: $color-gray-100;
        line-height: 20px;
      }
      &--reasons {
        margin: 16px 0;
        color: $color-gray-100;
        list-style: disc;
        padding-left: 13px;
        li {
          font-size: 14px;
          line-height: 20px;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  &__title {
    color: $color-white;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__description {
    color: $color-gray-100;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__link {
    margin: 0 4px;
    color: $color-primary-light;
    text-decoration-line: underline;
  }

  &__close {
    width: 32px;
    height: 32px;
  }

  &__actions {
    padding: 32px;
    justify-content: flex-end;
  }

  .detail-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &--title {
      color: $color-gray-100;
    }
    &--value {
      color: $color-white;
      font-variant: tabular-nums;
    }
  }

  .separator-line {
    width: 100%;
    height: 0.5px;
    background-color: $color-gray-400;
    margin: 32px 0;
  }

  .documents-title {
    display: block;
    margin-bottom: 16px;
  }

  .documents-list {
    .document-item {
      border-radius: 8px;
      border: 1px solid $color-gray-400;
      padding: 16px;
      display: flex;
      align-items: center;
      &--icon {
        width: 40px;
        height: 40px;
        border: 1px solid $color-gray-400;
        border-radius: 10rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      &--detail {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 16px;
      }
      &--name {
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      &--size {
        color: $color-gray-100;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 4px;
      }
      &--download-link {
        color: $color-gray-100;
        align-self: flex-start;
        appearance: none;
        background: transparent;
        border: 0;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__warning-icon {
    width: 1.5rem;
    height: 1.5rem;
    aspect-ratio: 1;
  }
}
