@import '@ping/assets/scss/theme/variables';

@mixin svgColor($color) {
  svg {
    path:not(.not-change-stroke),
    line,
    circle,
    rect {
      stroke: $color;
    }
    .not-change-stroke {
      fill: $color;
    }
  }
}
