.assetName-wrapper {
  display: flex;
  align-items: center;

  .asset-icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
