@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.language-currency-dialog__content {
  padding: 0;
  padding-block: 26px;
  position: relative;
  :global(.popup_close-btn) {
    margin-right: 32px;
    z-index: 5;
    cursor: pointer;
  }
}
.language-currency-dialog__tab-bar {
  transform: translate(0, 4px);
  border-radius: 2px;
  transition: $transition-hover;
  height: 4px;
  width: 200px;
  background-color: $color-primary;
}

.language-tabs {
  margin-top: -69px;
  padding-top: 10px;
  // This component and its tabs will be refactored in issue #612
  border-bottom: 1px solid #6b728f;

  [role='tab'] {
    padding: 12px 32px 26px;
  }
}

.language-dialog,
.currency-dialog {
  &__wrapper {
    padding: 32px;
    color: $color-white;
    min-height: 226px;
    h5 {
      margin-bottom: 32px;
    }
  }
  &__content {
    color: $color-white;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  &__item {
    margin-bottom: 32px;
    color: $color-gray-200;
    transition: $transition-hover;
    cursor: pointer;

    &:hover {
      color: $color-white;
    }
    &_active {
      color: $color-white;
      font-weight: 600;
      &:after {
        content: '';
        display: inline-block;
        height: 10px;
        width: 25px;
        background: url('/img/checkMark.svg') no-repeat center;
      }
    }
  }
}
