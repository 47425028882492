$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px,
);

@mixin media-breakpoint-down($name) {
  $max: map-get($breakpoints, $name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-up($name) {
  $min: map-get($breakpoints, $name);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($min-name, $max-name) {
  $min: map-get($breakpoints, $min-name);
  $max: map-get($breakpoints, $max-name);
  @if $min and $max {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
