@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.ping-toast__container {
  width: 336px;
  top: 89px;
  right: 40px;
  padding: 0;
  @include media-breakpoint-down('md') {
    right: 0;
    left: 16px;
  }
}

.ping-toast {
  font-family: inherit;
  width: 100%;
  padding: 16px;
  background: $color-gray-700 !important;
  color: $color-white !important;
  border-radius: 8px;
  border: 1px solid $color-gray-500;

  &__content-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  &__content-text {
    font-size: 12px;
    color: $color-gray-100;
    line-height: 16px;
    font-weight: 400;
  }
  &__content-text--color {
    color: $color-white;
  }

  :global {
    .Toastify {
      &__toast-body {
        padding: 0;
        margin: 0;
      }

      &__toast-icon {
        justify-content: flex-start;
        align-self: center;
        margin-inline-end: 12px;
      }

      &__close-button {
        align-self: center;
        margin-inline-start: 12px;

        svg {
          display: block;
        }
      }

      &__progress-bar {
        height: 4px;
      }
    }
  }

  &--default {
    :global {
      .Toastify {
        &__progress-bar {
          background: $color-gray-400 !important;
        }
      }
    }
  }

  &--progress {
    :global {
      .Toastify {
        &__toast-icon {
          color: $color-primary-light;
        }

        &__progress-bar {
          background: $color-primary-light !important;
        }
      }
    }
  }

  &--success {
    :global {
      .Toastify {
        &__toast-icon {
          color: $color-success;
        }

        &__progress-bar {
          background: $color-success !important;
        }
      }
    }
  }

  &--warning {
    :global {
      .Toastify {
        &__toast-icon {
          color: $color-warning;
        }

        &__progress-bar {
          background: $color-warning !important;
        }
      }
    }
  }

  &--error {
    :global {
      .Toastify {
        &__toast-icon {
          color: $color-error;
        }

        &__progress-bar {
          background: $color-error !important;
        }
      }
    }
  }
}
