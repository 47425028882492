@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.banner {
  & ~ [class*='page'] {
    top: calc($header-height + $banner-height);
    height: calc(100% - $header-height - $banner-height);
  }

  &_cursor {
    cursor: pointer;
  }
}

.banner-message {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  background: $color-gray-500;

  svg {
    margin-right: 8px;
  }

  &__error {
    border-bottom: 1px solid $color-error;

    svg {
      color: $color-error;
    }
  }

  &__succuss {
    border-bottom: 1px solid $color-success;

    svg {
      color: $color-success;
    }
  }
  &__warning {
    border-bottom: 1px solid $color-warning;

    svg {
      color: $color-warning;
    }
  }
}

.banner-modal {
  &__trigger {
    width: 100%;
    padding: 0;
    height: $banner-height;
    border: 0;
    cursor: pointer;
    user-select: none;
  }
}
