@import '@ping/assets/scss/breakpoint';

.modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-inline: 32px;
  padding-block-end: calc(var(--padding-block-end, 1.25rem) + env(safe-area-inset-bottom));

  @include media-breakpoint-up('lg') {
    padding-block-end: 0;
  }
}
