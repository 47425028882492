@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.component_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $color-white;
  transition: $transition-hover;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }

  &.design {
    &_general {
      &.type {
        &_primary {
          background: $color-primary;

          &:hover {
            background-color: $color-primary-medium;
          }

          &:disabled {
            background-color: $color-gray-400;
            color: $color-gray-200;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $color-primary;

          &:hover:not(.disabled) {
            border-color: $color-primary-medium;
          }

          &:disabled {
            background-color: $color-gray-600;
            border-color: $color-gray-300;
            color: $color-gray-300;
            cursor: not-allowed;
          }
        }
      }
    }

    // &_sell {
    //   &.type {
    //     &_primary {
    //       background: $color-error;
    //       border-color: $color-error;
    //       &:hover {
    //         background-color: $btn-red-hover;
    //         border-color: $btn-red-hover;
    //       }

    //       &.disabled {
    //         opacity: 0.38;
    //         cursor: not-allowed;
    //       }
    //     }

    //     &_secondary {
    //       border-color: $color-error;
    //       &:hover {
    //         border-color: $btn-red-hover;
    //       }
    //       &.disabled {
    //         opacity: 0.5;
    //         cursor: not-allowed;
    //       }
    //     }
    //   }
    // }

    // &_buy {
    //   &.type {
    //     &_primary {
    //       background: $color-success;
    //       border-color: $color-success;
    //       &:hover {
    //         background-color: $btn-green-hover;
    //         border-color: $btn-green-hover;
    //       }

    //       &.disabled {
    //         opacity: 0.38;
    //         cursor: not-allowed;
    //       }
    //     }

    //     &_secondary {
    //       border-color: $color-success;
    //       &:hover {
    //         border-color: $btn-green-hover;
    //       }
    //       &.disable {
    //         opacity: 0.5;
    //         cursor: not-allowed;
    //       }
    //     }
    //   }
    // }

    &_gray {
      &.type {
        &_primary {
          background: $color-gray-500;
          border-color: $color-gray-500;

          &:hover {
            background-color: $color-gray-500;
            border-color: $color-gray-500;
          }

          &.disabled {
            opacity: 0.38;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $color-gray-500;

          &:hover {
            border-color: $color-gray-500;
          }

          &.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &.size {
    &_large {
      padding: 12px 16px;
      width: 316px;
      height: 48px;
    }

    &_small {
      padding: 8px;
      width: 192px;
      height: 40px;
      font-size: 14px;
      line-height: 18px;
    }

    &_extra-large {
      padding: 12px 16px;
      width: 100%;
      height: 48px;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
