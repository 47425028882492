@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.provide-requested-documents-modal-portal {
  width: 864px;
  max-inline-size: 65%;
  max-block-size: 70%;
  @include media-breakpoint-down('md') {
    inline-size: 100%;
    max-inline-size: 100%;
    max-block-size: 94%;
  }

  &__header {
    padding: 2rem;
  }

  &__content {
    display: flex;
    padding: 1rem 2rem;
    align-items: stretch;
    @include media-breakpoint-down('md') {
      flex-direction: column;
    }
  }

  &__title {
    color: $color-white;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
    margin-block-end: 0.5rem;
  }

  &__details {
    width: 50%;
    display: inline-flex;
    flex-direction: column;
    margin-inline-end: 2.5rem;
    @include media-breakpoint-down('md') {
      width: 100%;
      margin-right: 0;
    }
  }

  &__upload-area {
    width: 50%;
    display: inline-block;
    border: 2px dashed $color-gray-300;
    border-radius: 0.5rem;

    @include media-breakpoint-down('md') {
      width: 100%;
      margin-block-start: 2rem;
      border: none;
    }

    &[data-populated='true'] {
      :global {
        .filepond--drop-label {
          height: auto !important;
          .upload-label--image {
            display: none;
          }
        }
      }
    }
    :global .filepond--wrapper {
      height: 434px;
      max-height: 100%;
      cursor: pointer;

      .filepond--root {
        height: 100% !important;

        .filepond--list-scroller {
          margin: 24px 0 !important;
        }

        .filepond--list {
          @include media-breakpoint-down('md') {
            left: 0;
            right: 2px;
            top: 8px;
          }
        }
      }
      .filepond--item {
        border: 1px solid $color-gray-400;
        border-radius: 8px;
        box-sizing: content-box;
        cursor: default;
        height: auto !important;
        margin: 0 24px 8px;

        @include media-breakpoint-down('md') {
          width: 100%;
          margin: 0 0 8px;
        }

        &-panel {
          background-color: $color-gray-700;
        }
        &[data-filepond-item-state='processing-error'],
        &[data-filepond-item-state='load-invalid'] {
          border: 1px solid $color-error;
        }
        &[data-filepond-item-state='load-invalid'] {
          .filepond--file-status {
            display: flex;
            left: 4em !important;
            position: absolute;
            margin: 0 !important;
            transform: unset !important;
            align-items: flex-start;
            top: 2.5em;
            color: $color-error;
            .filepond--file-status-main {
              display: none;
            }
            .filepond--file-status-sub {
              opacity: 1;
              overflow-wrap: break-spaces;
              white-space: unset;
              text-align: left;
            }
          }
          .filepond--file-info-sub {
            visibility: hidden;
          }
        }
      }
      .filepond--file {
        padding: 16px;
        [data-align*='right'] {
          left: 0.5625em;
          right: unset;
        }
        [data-align*='left'] {
          right: 0.5625em;
          left: unset;
        }
        .filepond--action-retry-item-load,
        .filepond--action-retry-item-processing {
          right: 2em;
          left: unset;
        }
        .filepond--action-abort-item-processing {
          right: 0.5625em;
          left: unset;
        }
        .filepond--processing-complete-indicator {
          visibility: visible !important;
          opacity: 1 !important;
          transform: unset !important;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $color-gray-400;
          border-radius: 10rem;
          margin-top: 0;
        }
        .filepond--progress-indicator {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          margin-top: 1px;
          svg {
            transform: scale(1.13);
          }
        }
        &-info {
          transform: unset !important;
          margin-left: 3em;
        }
        &-status {
          display: none;
        }
        &-action-button {
          background: transparent;
          color: $color-gray-100;
          transition: all 200ms ease;
          &:hover {
            color: $color-white;
            box-shadow: none;
          }
        }
        &-info-main {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        &-info-sub {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin-top: 4px;
        }
      }
      .filepond--drop-label {
        height: 100%;

        @include media-breakpoint-down('md') {
          border: 2px dashed $color-gray-300;
          border-radius: 8px;
          height: 72px;
          justify-content: flex-start;
        }

        label {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 48px 0 !important;

          @include media-breakpoint-down('md') {
            padding: 16px !important;
            align-items: flex-start;
            flex-direction: row;
            .upload-label--content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 16px;
            }
          }

          .upload-label {
            &--image {
              width: 96px;
              margin-bottom: 16px;
            }
            &--title {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.14px;
              color: $color-white;
              margin-bottom: 4px;
              @include media-breakpoint-down('md') {
                font-weight: 400;
              }
            }
            &--text {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $color-gray-100;
            }
          }

          .has-underline {
            font-weight: 600;
            text-decoration-line: underline;
          }
        }
      }
      .filepond--action-revert-item-processing {
        display: none;
      }
      .filepond--panel-root {
        background: transparent;
      }
    }
  }

  &__actions {
    padding: 2rem;
    justify-content: end;
  }

  &__submit {
    inline-size: calc((100% - 2.5rem) * 0.5) !important;
  }

  &__close {
    inline-size: 2rem;
    block-size: 2rem;
  }
}

.provide-requested-documents-modal-portal-alert {
  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    aspect-ratio: 1;
  }
}
