@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.modal-close-button {
  appearance: none;
  background: none;
  display: grid;
  place-content: center;
  padding: 0.5rem;
  border-radius: 50rem;
  outline: none;

  &__icon {
    aspect-ratio: 1;
    color: $color-gray-100;
  }

  svg {
    transition: $transition-hover;
  }

  &:hover {
    svg {
      color: $color-white;
    }
  }
}
