.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
