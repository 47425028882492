$header-height-value: 72;
$header-height: #{$header-height-value}px;
$banner-height: 41px;
$card-header-height: 48px;
$trade-page-border-radius: 8px;
$trading-records-height: 22rem;
$trading-records-height-mobile: 500px;
$transition-hover: all 0.3s ease-in-out;
$border-radius: 3px;
$sidebar-width: 240px;
$sidebar-inline-size: 20rem;
$layout-max-width: 1124px;
$layout-max-width-with-padding: $layout-max-width + 80px;
