@import '@ping/assets/scss/theme/variables';

.alert-root {
  padding: 1rem;
  align-self: stretch;
  display: grid;
  grid-template-columns: 1.5rem auto;
  align-items: flex-start;
  gap: 0.25rem 0.5rem;

  background: $color-gray-600;
  border: 1px solid $color-gray-400;
  border-radius: 0.25rem;
}
