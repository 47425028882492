@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

@keyframes modal-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  60% {
    transform: scale(1.1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes modal-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 900;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-gray-200;
  border-radius: 3px;
  outline: none;

  &.fullScreen {
    border-radius: 0;
    inset: 0;
    transform: translate(0, 0);

    .popup__content {
      padding: 0;
      background-color: $color-black;
      inline-size: 100vw;
      block-size: 100vh;
      max-block-size: 100%;

      @include media-breakpoint-up('md') {
        padding: 2rem;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(19, 23, 34, 0.7);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    align-items: center;

    &.center {
      justify-content: start;

      .popup__left-part {
        justify-content: center;
        width: 100%;
      }
    }
  }

  &__cross {
    width: 12px;
    height: 12px;
    cursor: pointer;
    z-index: 1;
  }

  &__content {
    animation: modal-scale 0.2s linear;
    background: $color-gray-700;
    border-radius: 3px;
    padding: 32px;
    width: 576px;
    height: auto;
    // TODO: temporarily handle overflow until design will be completed
    max-height: calc(75vh + 2 * 38px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 396px;
      padding: 24px;
      overflow-x: hidden;
    }

    @media (max-height: 700px) {
      max-height: 656px;
    }

    @media (min-height: 701px) and (max-height: 830px) {
      max-height: 755px;
    }

    &_with-scroll {
      overflow-y: auto;
      max-height: 100vh;
    }

    &_closing {
      animation: modal-out 0.1s linear;
      opacity: 0;
    }
  }

  &__left-part {
    display: flex;
  }

  &__left-part-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $color-white;
    letter-spacing: -0.01em;
    margin: 0;
  }

  :global(.popup_close-btn) {
    color: $color-gray-100;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
    cursor: pointer;

    path {
      transition: $transition-hover;
    }
    &:hover {
      color: $color-error !important;
    }
  }

  &__back {
    color: $color-white;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
    cursor: pointer;
  }
}
