.api-error-response-toast-content {
  &__errors {
    padding-inline-start: 1.25rem;

    &:not(:empty) {
      margin-top: 0.25rem;
    }
  }

  &__error {
    list-style-type: disc;

    & + & {
      margin-top: 0.125rem;
    }
  }
}
