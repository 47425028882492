$color-white: #ffffff;
$color-black: #131722;

$color-gray-100: #b3b8d0;
$color-gray-200: #767d99;
$color-gray-300: #3e445c;
$color-gray-400: #30364d;
$color-gray-500: #24293a;
$color-gray-600: #1d2130;
$color-gray-700: #181c2a;

$color-primary: #3749e7;
$color-primary-light: #4e60ff;
$color-primary-medium: #2536c8;
$color-primary-dark: #1725a0;

$color-secondary: #8090d3;
$color-secondary-light: #9daae0;
$color-secondary-medium: #6172b8;
$color-secondary-dark: #485797;

$color-success: #10c36d;
$color-success-light: #4ddc97;
$color-success-medium: #0baa5d;
$color-success-dark: #06894a;

$color-warning: #f9cf3a;
$color-warning-light: #ffe071;
$color-warning-medium: #ddb21b;
$color-warning-dark: #bb9200;

$color-error: #f93a59;
$color-error-light: #ff7b91;
$color-error-medium: #da2442;
$color-error-dark: #b0162f;

$color-corepass-primary: #1066df;
$color-corepass-secondary: #dce1f9;
$color-corepass-gray-medium: #344357;
$color-corepass-black: #030c1a;

//
// TODO: maybe later we can override some of th the TradingView styles?
$color-tv-primary: #2962ff;
$color-tv-text: #d1d4dc;

$color-orange: #f97f3a;
