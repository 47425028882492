@import '@ping/assets/scss/theme/variables';

.alert-description {
  margin: 0; // reset browser style
  grid-column-start: 2;
  color: $color-gray-100;
  text-wrap: balance;
  word-break: break-word;

  /* 02 Body/regular */
  font-family: inherit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}
